import { render, staticRenderFns } from "./HeaderLogoBox.vue?vue&type=template&id=3aad31b1&scoped=true&"
import script from "./HeaderLogoBox.vue?vue&type=script&lang=js&"
export * from "./HeaderLogoBox.vue?vue&type=script&lang=js&"
import style0 from "./HeaderLogoBox.vue?vue&type=style&index=0&id=3aad31b1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aad31b1",
  null
  
)

export default component.exports
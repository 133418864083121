<template>
  <div id="footer" class="darkbluebg blue">
      <span class="beian" @click="tobeian">京ICP备16058577号-2</span>
      <span class="company_name">爱影一心（北京）信息咨询有限公司</span>
      <span class="company_name">联系我们 contact@ultra-optimization.com</span>
  </div>
</template>

<script>
export default {
  methods:{
    tobeian(){
      window.location.assign('https://beian.miit.gov.cn')
    }
  }
};
</script>

<style scoped>
#footer {
  height: 90px;
  text-align: center;
  line-height: 86px;
  font-size: 12px;
  font-family: "PingFang SC","Microsoft Yahei",Arial;
}
.darkbluebg {
  /* background: #3d5368; */
  background-color: #f7fbfd;
}
.blue {
  color: #51b8cb;
}
.company_name {
    margin-left: 20px;
}
.beian {
  cursor: pointer;
}
.beian:hover {
  opacity: 0.8;
  border-bottom: 1px solid;
}
</style>